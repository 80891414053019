import {ChangeDetectorRef, Component, Inject, Input, OnInit, Output} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {WooapiService} from '../../wooapi.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TestObject} from 'protractor/built/driverProviders';

export interface DialogData {
  list?;
  cats?;
  fullList?;
  total?: number;
}

@Component({
  selector: 'app-list-dialog',
  templateUrl: './list-dialog.component.html',
  styleUrls: ['./list-dialog.component.css']
})
export class ListDialogComponent implements OnInit {

  cats = {
    389: 'מעבדים',
    410: 'פתרונות קירור',
    390: 'לוחות אם',
    392: 'זכרונות',
    401: 'דיסקים קשיחים HDD',
    402: 'דיסקים קשיחים SSD',
    403: 'דיסקים קשיחים SSD NVMe',
    417: 'כרטיסי מסך',
    406: 'מארזים',
    408: 'ספקי כוח',
    420: 'מסכים',
    451: 'ציוד היקפי',
    454: 'עכברים',
    453: 'מקלדות',
    452: 'סט ציוד היקפי',
    414: 'תוכנות',
    457: 'הומלץ על ידי'
  };

  @Input()
  listId: string;

  constructor(
    public dialogRef: MatDialogRef<ListDialogComponent>,
    public api: WooapiService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public cd: ChangeDetectorRef) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    console.log(this.listId);
    console.log(this.data);
    this.cd.detectChanges();
  }


  // getList() {
  //  return this.data.list ? this.data.list : this.list;
  // }
  //
  // getTotal() {
  //   return this.data.total ? this.data.total : this.total;
  // }

  getCategoryName(catId: number) {
    return this.data.cats.filter(a => a.id === catId)[0]?.name;
  }

  getCatValues(item) {
    const val = [...item.value.values()];
    return val;
  }


  addToCart() {
    console.log(this.data.fullList);
    this.api.addToCart(this.data.fullList);
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  order() {
    this.api.createOrder({});
  }

  async create() {
    //const list = Array.from(this.data.fullList);
    const data: DialogData = {
      cats: {},
      list: {},
      fullList: [],
      total: 0
    }
    ;
    Object.assign(data.cats, this.data.cats);
    data.total = this.data.total;
    data.fullList = [...this.data.fullList];
    for (const l in this.data.list) {
      data.list[l] = Array.from(this.data.list[l]);
    }
    console.log(data);
    const res = await this.api.createList(data);
    console.log(res.listId);
    window.open('./?list=' + res.listId, '_blank');
    // this.api.savedSubject().next(res.listId);
  }
}
