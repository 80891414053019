import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module'
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { ProductListComponent } from './components/product-list/product-list.component';
import {FormsModule} from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ListDialogComponent } from './components/list-dialog/list-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {NgxPrintModule} from 'ngx-print';
import { RecaptchaModule } from 'ng-recaptcha';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {ListSavedComponent} from './components/list-saved/list-saved.component';
import {ClipboardModule} from 'ngx-clipboard';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';

@NgModule({
  declarations: [
    AppComponent,
    ProductListComponent,
    ListDialogComponent,
    ListSavedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    MatTooltipModule,
    MatDialogModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgxPrintModule,
    RecaptchaModule,
    AppRoutingModule,
    ClipboardModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
