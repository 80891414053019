import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import Categories from '../assets/categories.json';
import {Product} from './app.component';

const consumerKey = 'ck_ce55f0d10510037ec20e0469f6125f3103304a84';
const consumerSecret = 'cs_59f5e35a444ab0e1750c99af22d134e14a551dc4';

let headers_object = new HttpHeaders();
headers_object.append('Content-Type', 'application/json');
headers_object.append('Authorization', 'Basic ' + btoa(`${consumerKey}:${consumerSecret}`));

// const httpOptions = {
//   headers: headers_object
// };
// const server = 'http://ec2-18-159-82-198.eu-central-1.compute.amazonaws.com:3000';

const server = 'https://ocpcbuild.co.il';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    // Authorization: "Basic " + btoa(`${consumerKey}:${consumerSecret}`)
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class WooapiService {

  $savedList = new BehaviorSubject<string>(null);

  constructor(private http: HttpClient) { }

  savedSubject() {
    return this.$savedList;
  }

  getCategories(): Observable<any> | any {
    return Categories;
    // return this.http.get('https://ocpc.co.il/wp-json/wc/v3/products/categories?per_page=100', httpOptions)
  }

  // getProducts(page): Observable<any> {
  //   return this.http.get(`https://ocpc.co.il//wp-json/wc/v3/products?page=${page}&per_page=100`, httpOptions)
  // }

  getProducts(): Observable<any> {
    return this.http.get(`https://ocpc.s3.us-east-2.amazonaws.com/categories.json`);

    // return this.http.get(`categories.json`);
  }

  addToCart(list) {
    // console.log('API: addToCart', list);
    for (const prod of list) {
      console.log('adding' + prod.id);
      this.http.get(`https://ocpc.co.il/?add-to-cart=${prod.id}&quantity=${prod.quantity}`, httpOptions).subscribe((res) => {
        console.log(res);
      });
    }
  }

  createOrder(order) {
    console.log('Creating order', order);
    const data = {
      payment_method: 'bacs',
      payment_method_title: 'Direct Bank Transfer',
      set_paid: false,
      shipping: {
        first_name: 'John',
        last_name: 'Doe',
        address_1: '969 Market',
        address_2: '',
        city: 'San Francisco',
        state: 'CA',
        postcode: '94103',
        country: 'US'
      },
      line_items: [
        {
          product_id: 3493,
          quantity: 2
        },
        {
          product_id: 3117,
          quantity: 1
        }
      ],
    };
    this.http.post(`https://ocpc.co.il/wp-json/wc/v3/orders`, data, httpOptions).subscribe((res) => {
      console.log(res);
    });

    return 'OK';
  }

  async createList(data): Promise<{listId?: string}> {
    console.log('sending list');
    console.log(data);
    return await this.http.post(`${server}/save`,
      data, httpOptions).toPromise();
  }

  async getList(id): Promise<any> {
    console.log(id);
    // return await this.http.get('http://d3sujq1qbomliz.cloudfront.net/' + id + '.json', httpOptions)
    return await this.http.get(`${server}/list/${id}`, httpOptions)
      .toPromise();
  }

  async order(name, phone, email, id): Promise<any> {
    console.log('Sending order');
    const data = {
      id,
      name,
      phone,
      email
    };
    return await this.http.post(`${server}/order`,
      data, httpOptions).toPromise();
  }

  // getCategoryProducts(page, category): Observable<any> {
  //   return this.http.get(`https://ocpc.co.il//wp-json/wc/v3/products?page=${page}&per_page=100&category=${category}`, httpOptions)
  // };
}
