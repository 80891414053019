import {ChangeDetectorRef, Component, Inject, Input, OnInit, Output} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {WooapiService} from '../../wooapi.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TestObject} from 'protractor/built/driverProviders';

export interface DialogData {
  list?;
  cats?;
  fullList?;
  total?: number;
}

@Component({
  selector: 'app-list-saved',
  templateUrl: './list-saved.component.html',
  styleUrls: ['./list-saved.component.css']
})
export class ListSavedComponent implements OnInit {

  cats = {
    389: 'מעבדים',
    410: 'פתרונות קירור',
    390: 'לוחות אם',
    392: 'זכרונות',
    401: 'דיסקים קשיחים HDD',
    402: 'דיסקים קשיחים SSD',
    403: 'דיסקים קשיחים SSD NVMe',
    417: 'כרטיסי מסך',
    406: 'מארזים',
    408: 'ספקי כוח',
    420: 'מסכים',
    451: 'ציוד היקפי',
    454: 'עכברים',
    453: 'מקלדות',
    452: 'סט ציוד היקפי',
    414: 'תוכנות',
    457: 'הומלץ על ידי'
  };

  @Input()
  listId: string;

  data: DialogData = {};

  loading = true;

  name: string;
  email: string;
  phone: string;
  displayedColumns: string[] = ['name', 'quantity', 'price'];
  sending: boolean;
  sendingStatus: string;

  constructor(
    public api: WooapiService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public cd: ChangeDetectorRef) {
  }

  async ngOnInit(): Promise<void> {
    console.log(this.listId);
    this.data = await this.getList();
    console.log(this.data);
    this.loading = false;
    this.cd.detectChanges();
  }

  // getList() {
  //  return this.data.list ? this.data.list : this.list;
  // }
  //
  // getTotal() {
  //   return this.data.total ? this.data.total : this.total;
  // }

  async getList() {
   return this.api.getList(this.listId);
  }

  getCategoryName(catId: number) {
    return this.data.cats.filter(a => a.id === catId)[0]?.name;
  }

  getCatValues(item) {
    const val = [...item.value.values()];
    return val;
  }


  addToCart() {
    console.log(this.data.fullList);
    this.api.addToCart(this.data.fullList);
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  async order() {
    // this.api.createOrder({});
    this.sending = true;
    try {
      const response = await this.api.order(this.name, this.phone, this.email, this.listId);
      this.sendingStatus = 'הזמנה נשלחה בהצלחה! נציג יחזור אליך ב 24 השעות הקרובות';
      console.log(response);
      this.sending = false;
    } catch (e) {
      console.log(e);
      this.sendingStatus = 'היתה בעיה בשליחת ההזמנה, ניתן ליצור קשר טלפוני';
      this.sending = false;
    }
  }

  async create() {
    // const list = Array.from(this.data.fullList);
    const list = {};
    Object.assign(list, this.data.list);
    for (const l in list) {
      list[l] = Array.from(list[l]);
    }
    console.log(list);
    const res = await this.api.createList(this.data);
    console.log(res.listId);
    window.open('./?list=' + res.listId, '_blank');
    // this.api.savedSubject().next(res.listId);
  }
}
