import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AppComponent} from './app.component';
import {ListDialogComponent} from './components/list-dialog/list-dialog.component';

const routes: Routes = [
  {
    path: 'list/:id',
    component: ListDialogComponent
  },
  {
    path: '',
    component: AppComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
