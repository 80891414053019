<h2 mat-dialog-title>המפרט שנבחר</h2>
<mat-dialog-content class="mat-typography">
  <div *ngFor="let item of data.list | keyvalue">
    <h2 *ngIf="item.value.size !== 0"><b>{{cats[item.key]}}</b></h2>
    <p *ngFor="let prod of getCatValues(item)" fxLayout="row" fxLayoutAlign="space-between center">
      <span fxLayout="row" fxLayoutAlign="start center">
        <mat-icon>keyboard_arrow_left</mat-icon>{{prod.name}}
        <span style="margin-right: 10px;"><b>x</b></span>
      <span style="margin-right: 10px;"><b>{{prod.quantity}}</b></span>
      </span>
      <span>₪{{prod.price * prod.quantity}}</span>
    </p>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <div style="text-align: left">סה״כ: <b>{{data.total}}</b></div>
<!--  <button mat-button mat-dialog-close>הדפס</button>-->
<!--  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="addToCart()">הוסף לעגלת הקניות</button>-->

<!--  <button mat-button mat-dialog-close (click)="order()">שליחת הזמנה</button>-->
  <button mat-button mat-dialog-close (click)="create()" style="margin-right: 10px;">שמור רשימה</button>

</mat-dialog-actions>
