
<div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center"><mat-spinner diameter="10"></mat-spinner></div>

<div *ngIf="!loading" fxLayout="column" fxLayoutAlign="start start" fxFill style="width: 100%;">
  <div *ngFor="let item of data.list | keyvalue" style="width: 100%;">
    <div *ngIf="item.value.length !== 0" style="width: 100%;">
      <h2><b>{{cats[item.key]}}</b></h2>
      <p *ngFor="let prod of item.value" fxLayout="row" fxLayoutAlign="space-between center">
        <span fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>keyboard_arrow_left</mat-icon>{{prod.name}}
          <span style="margin-right: 10px;"><b>x</b></span>
        <span style="margin-right: 10px;"><b>{{prod.quantity}}</b></span>
        </span>
        <span>₪{{prod.price * prod.quantity}}</span>
      </p>

    </div>
  </div>
  <hr>
  <h3 class="mt-10" style="text-align: left">סה״כ: <b>₪{{data.total}}</b></h3>
  <div style="font-weight: bold;">* המחיר למזומן / העברה בנקאית - תשלום באשראי בתוספת 2%</div>
  <div fxLayout="column" style="margin-top: 30px;">
    <div fxLayout="row">
      <mat-form-field appearance="outline" >
        <mat-label>שם מלא</mat-label>
        <input matInput type="text" [(ngModel)]="name" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>טלפון</mat-label>
        <input matInput type="text" [(ngModel)]="phone" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>אימייל</mat-label>
        <input matInput type="text" [(ngModel)]="email" required>
      </mat-form-field>
    </div>
    <div>
      <button mat-raised-button  color="primary" (click)="order()" [disabled]="!name || !email || !phone">בצע הזמנה</button>
      <a href="./" mat-raised-button style="margin-right: 10px;">בנה מפרט חדש</a>
      <div><mat-spinner diameter="10"></mat-spinner></div>
      <div>{{this.sendingStatus}}</div>
    </div>
  </div>
  <!--  <button mat-button mat-dialog-close>הדפס</button>-->
  <!--  <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="addToCart()">הוסף לעגלת הקניות</button>-->

  <!--  <button mat-button mat-dialog-close (click)="order()">שליחת הזמנה</button>-->
<!--  <button mat-button mat-dialog-close (click)="create()">שמור רשימה</button>-->
</div>

