import { WooapiService } from './wooapi.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {MatDrawer} from '@angular/material/sidenav';
import {MatDialog} from '@angular/material/dialog';
import {ListDialogComponent} from './components/list-dialog/list-dialog.component';
import {ActivatedRoute} from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

const consumerKey = "ck_ce55f0d10510037ec20e0469f6125f3103304a84";
const consumerSecret ="cs_59f5e35a444ab0e1750c99af22d134e14a551dc4";

var headers_object = new HttpHeaders();
headers_object.append('Content-Type', 'application/json');
headers_object.append("Authorization", "Basic " + btoa(`${consumerKey}:${consumerSecret}`));

export interface Product {
  id: number;
  name: string;
  permalink: string;
  price: number;
  quantity: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    Authorization: "Basic " + btoa(`${consumerKey}:${consumerSecret}`)
  })
};

// const api = new WooCommerceRestApi({
//   url: "http://example.com",
//   consumerKey: "ck_ce55f0d10510037ec20e0469f6125f3103304a84",
//   consumerSecret: "cs_59f5e35a444ab0e1750c99af22d134e14a551dc4",
//   version: "wc/v3"
// });

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'ocpc';
  categories = [];
  categoriesRaw;
  displayCategories = [
    'מעבדים',
    'פתרונות קירור',
    'לוחות אם',
    'זכרונות',
    'דיסקים קשיחים HDD',
    'דיסקים קשיחים SSD',
    'דיסקים קשיחים SSD NVMe',
    'כרטיסי מסך',
    'מארזים',
    'ספקי כוח',
    'מסכים',
    'ציוד היקפי',
    'עכברים',
    'מקלדות',
    'סט ציוד היקפי',
    'תוכנות',
    'הומלץ על ידי'
  ];
  products;
  searchInput: string;
  loading = true;
  currentItems: Product[] = [];
  allProducts = [];
  currentCategory = {id: 389, name: 'מעבדים'};
  savedListId;
  savedList;
  savedTotal;

  selected = new Set<Product>();
  selectedCat = {
    401: new Set<Product>(),
    402: new Set<Product>(),
    392: new Set<Product>(),
    417: new Set<Product>(),
    390: new Set<Product>(),
    406: new Set<Product>(),
    403: new Set<Product>(),
    420: new Set<Product>(),
    389: new Set<Product>(),
    453: new Set<Product>(),
    452: new Set<Product>(),
    408: new Set<Product>(),
    454: new Set<Product>(),
    410: new Set<Product>(),
    451: new Set<Product>(),
    414: new Set<Product>(),
    457: new Set<Product>()
  };

  @ViewChild('scroller')
  scroller: CdkVirtualScrollViewport;

  @ViewChild('drawer')
  drawer: MatDrawer;

  @ViewChild(MatSort) sort: MatSort;

  dataSource;

  showFiller = false;

  displayedColumns: string[] = ['name', 'price', 'quantity', 'add'];

  constructor(private api: WooapiService, public dialog: MatDialog,
              private route: ActivatedRoute,
              private cd: ChangeDetectorRef) {
    console.log(this.route.queryParams);
    this.route.queryParams.subscribe((params) => {
      if ( params.list ) {
        this.savedListId = params.list;
        this.loadList(this.savedListId);
      }
    });

  }

  ngAfterViewInit(): void {
    this.api.savedSubject().subscribe((list) => {
      if ( list === null ) {
        return;
      }
      console.log('SAVED LIST CHANGE', list);
      this.savedListId = list;
      this.loadList(this.savedListId);
    });
    this.categoriesRaw = this.api.getCategories();
    this.updateCategories();
    this.api.getProducts().subscribe((res) => {

      this.allProducts = res;
      this.currentItems = this.allProducts[this.currentCategory.id];
      this.dataSource = new MatTableDataSource(this.currentItems);
      console.log(this.currentItems);
      this.loading = false;
      this.cd.detectChanges();
      this.dataSource.sort = this.sort;
      console.log(this.sort);
    });
    }

  ngOnInit(): void {
    // for (const catIdx in this.categoriesRaw) {
    //   const cat = this.categoriesRaw[catIdx];
    //   if (this.displayCategories.has(cat.name)) {
    //     this.categories.push(cat);
    //     console.log('pushed ' + cat.name);
    //   }
    // }
    console.log(this.categories);
    // this.api.getCategories().subscribe((res) => {
    //   this.categories = res;
    //   this.loading = false;
    // })
    // this.api.getProducts().subscribe((res) => {
    //   this.products = res;
    //   console.log(this.products);
    //   this.loading = false;
    // })

  }

  async loadList(id) {
    this.savedList = await this.api.getList(id);
    console.log('loaded list ', this.savedList);
  }

  getCategoryInfo(cat: string): any {
    const result = this.categoriesRaw.find(c => {
      return c.name === cat;
    });
    return Object.assign({}, result);
  }

  updateCategories(): any {
    for (const catIdx in this.displayCategories) {
      const cat = this.displayCategories[catIdx];
      console.log(this.getCategoryInfo(cat));
      this.categories.push(this.getCategoryInfo(cat));
    }
  }

  changeCat(cat): any {
    this.currentCategory = cat;
    console.log(cat.id);
    this.currentItems = this.allProducts[cat.id];
    this.dataSource = new MatTableDataSource(this.currentItems);
    this.dataSource.sort = this.sort;
    this.searchInput = '';
    this.updateSearch();
    // this.scroller.scrollToIndex(0);
  }

  updateSearch(): void {
    if (this.searchInput.length === 0) {
      this.currentItems = this.allProducts[this.currentCategory.id];
    } else {
      this.currentItems = this.allProducts[this.currentCategory.id].filter(p => {
        return p.name.toLowerCase().includes(this.searchInput.toLowerCase());
      });
    }
    this.dataSource = new MatTableDataSource(this.currentItems);
    this.dataSource.sort = this.sort;
  }

  isSelected(item) {
    return this.selected.has(item);
  }

  select(item: Product) {
    console.log(item);
    if (this.selected.has(item)) {
      this.selected.delete(item);
      this.selectedCat[this.currentCategory.id].delete(item);
      item.quantity = 0;
    } else {
      if (item.quantity === 0) {
        item.quantity = 1;
      }
      this.selected.add(item);
      this.selectedCat[this.currentCategory.id].add(item);
    }
    console.log(this.selected);
  }

  toggleDrawer(): void {
    if (this.drawer.opened) {
      this.drawer.close();
      return;
    }
    this.drawer.open();
  }


  calcTotal(): number {
    let sum = 0;
    for(let item of this.selected.values()) {
      const price = +item.price;
      sum += price * item.quantity;
    }

    return sum;
  }

  openDialog() {

    const dialogRef = this.dialog.open(ListDialogComponent, {
      width: '100vw',
      data: {list: this.selectedCat, cats: this.categories, fullList: this.selected, total: this.calcTotal()}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


}

