<div fxLayout="column" fxFill>
  <mat-toolbar color="primary">
    <button *ngIf="!savedListId" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="toggleDrawer()">
      <mat-icon>menu</mat-icon>
    </button>
    <span clas="mr-20"><a href="./"><img class="logo" src="https://www.ocpc.co.il/wp-content/uploads/2021/04/ocpc-logo-transparent.png"></a></span>
    <span class="mr-10">בונה המפרטים</span>
    <button *ngIf="!savedListId" mat-raised-button (click)="openDialog()" class="done-button">הצגת מפרט</button>

  </mat-toolbar>
  <div *ngIf="savedListId" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="order">
    <div fxLayout="column" fxLayoutAlign="start center">
      <h2>מציג מפרט: {{savedListId}}</h2>
      <div > המפרט זמין לשיתוף בקישור הבא:</div>
      <div class="mt-10" fxLayout="row">
        <button mat-icon-button [ngxClipboard]="formInputText"><mat-icon style="font-size: 18px;">content_copy</mat-icon></button>
        <input type="text" style="width: 500px; direction: ltr; padding-left: 10px; border-radius: 5px; border: 1px solid #000" #formInputText value="http://pcbuild.ocpc.co.il/?list={{savedListId}}"/>
      </div>
    </div>
<!--    <div *ngFor="let item of savedList">-->
<!--      <div>{{item.name}}</div>-->
<!--      <div>{{item.quantity}}</div>-->

<!--    </div>-->
      <app-list-saved [listId]="savedListId"></app-list-saved>
    </mat-card>
  </div>
  <mat-drawer-container *ngIf="!loading && !savedListId" class="wrapper" fxLayout="row" autosize [ngClass]="{'opened': drawer.opened}" fxFlex="">
    <mat-drawer #drawer class="example-sidenav" mode="side" opened>
      <div fxLayout="column" class="menu" fxFill>
        <button mat-raised-button color="primary" class="cat-button" *ngFor="let cat of categories" (click)="changeCat(cat)">{{cat.name}}</button>
      </div>
    </mat-drawer>
    <div fxLayout="column" fxLayoutAlign="start start" class="products-wrapper" fxLayoutGap="gappx">
      <div class="search-wrapper" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="gappx">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>חיפוש מוצר בקטגוריה</mat-label>
          <input matInput type="text" [(ngModel)]="searchInput" (ngModelChange)="updateSearch()">
          <button mat-button *ngIf="searchInput" matSuffix mat-icon-button aria-label="Clear" (click)="searchInput=''; updateSearch();">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
  <!--      <input type="text" placeholder="חיפוש">-->
  <!--      <button mat-icon-button><mat-icon>search</mat-icon></button>-->
      </div>
      <div class="category-label">{{currentCategory.name}}</div>
<!--      <div><app-product-list [items]="currentItems"></app-product-list></div>-->
<!--      <div>-->
<!--        <cdk-virtual-scroll-viewport #scroller itemSize="35" class="example-viewport">-->
<!--          <div *cdkVirtualFor="let item of currentItems" class="product-item" [ngClass]="{'selected': isSelected(item)}" (click)="select(item)">{{item.name}}</div>-->
<!--        </cdk-virtual-scroll-viewport>-->
<!--      </div>-->
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 product-table">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> שם </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef> כמות </th>
          <td mat-cell *matCellDef="let element">
            <input matInput class="quantity" type="number" min="0" [(ngModel)]="element.quantity">
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> מחיר </th>
          <td mat-cell *matCellDef="let element" > {{element.price}} ₪</td>
        </ng-container>

        <!-- Symbol Column -->
<!--        <ng-container matColumnDef="link">-->
<!--          <th mat-header-cell *matHeaderCellDef> </th>-->
<!--          <td mat-cell *matCellDef="let element" style="text-align: left"><a mat-raised-button [href]="element.permalink" target="_blank">צפייה במוצר</a></td>-->
<!--        </ng-container>-->

        <!-- Symbol Column -->
        <ng-container matColumnDef="add">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element" style="text-align: left; padding-left: 10px;">
            <a mat-raised-button [href]="element.permalink" target="_blank">צפייה במוצר</a>
            <button mat-raised-button (click)="select(element)" class="select-button mr-10" matTooltip="{{isSelected(element) ? 'הסר מהרשימה' : 'הוסף לרשימה'}}">
              <mat-icon>{{isSelected(element) ? 'remove' : 'add'}}</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns; let entry" [ngClass]="{'selected': isSelected(entry)}"></tr>
      </table>


    </div>
  </mat-drawer-container>
  <div *ngIf="loading" fxLayout="row" fxLayoutAlign="center center"><mat-spinner diameter="10"></mat-spinner></div>
</div>

<!--<mat-drawer-container class="example-container" autosize>-->
<!--  <mat-drawer #drawer class="example-sidenav" mode="side">-->
<!--    <p>Auto-resizing sidenav</p>-->
<!--    <p *ngIf="showFiller">Lorem, ipsum dolor sit amet consectetur.</p>-->
<!--    <button (click)="showFiller = !showFiller" mat-raised-button>-->
<!--      Toggle extra text-->
<!--    </button>-->
<!--  </mat-drawer>-->

<!--  <div class="example-sidenav-content">-->
<!--    <button type="button" mat-button (click)="drawer.toggle()">-->
<!--      Toggle sidenav-->
<!--    </button>-->
<!--  </div>-->

<!--</mat-drawer-container>-->
